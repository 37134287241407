<template>
  <div class="code_wrap">
    <p>(手机上传方式)请微信扫描二维码，上传选择题答案和主观题图片。</p>
    <div class="qrcode"
         ref="qrCodeUrl"></div>

  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
export default {
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  methods: {
    creatQrCode (id) {
      console.log(id)
      let token = JSON.parse(window.localStorage.getItem('userInfo')).token
      // let text = `http://stu.sunray365.com/#/code?token=${token}&id=${id}`
      // let text = (process.env.NODE_ENV === 'development' ? 'http://192.168.101.118:10001' : 'http://stu.sunray365.com') + `/#/code?token=${token}&id=${id}`
      let url = window.location.host  // 获取当前主机名和端口
      let text = 'http://' + url + `/#/code?token=${token}&id=${id}`
      console.log(text)
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text, // 需要转换为二维码的内容
        width: 200,
        height: 200,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
  }
}
</script>

<style lang="scss" scoped>
p {
  font-size: 20rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10rem;
}
.code_wrap {
  margin-top: 20rem;
  margin-left: 20rem;
  // height: 200px;
}
</style>